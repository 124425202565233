'use client'

import { useTranslations } from 'next-intl'

import { Box, spacing } from '@fortum/elemental-ui'

import type { AppLinksEntry } from '@/shared/contentful/types'
import { useLocale } from '@/shared/locale'
import { getStaticAssetPath } from '@/shared/utils/staticAssets'

import { ImageLink } from '../ImageLink'

export type MobileAppDownloadProps = Omit<AppLinksEntry, 'sys' | '__typename'> & {
  /**
   * Wrapper props
   */
  wrapperProps?: BoxProps<
    never,
    'width' | 'height' | 'alignItems' | 'display' | 'flexDirection' | 'mt' | 'gap'
  >
}

/**
 * Present mobile store badge depends on selected locale.
 * Only visible on mobile devices.
 */
export const MobileAppDownload = ({
  googlePlayLink,
  appStoreLink,
  wrapperProps,
}: MobileAppDownloadProps) => {
  const locale = useLocale()
  const t = useTranslations('app')

  return (
    <Box
      display={{ default: 'flex', l: 'none' }}
      flexDirection="column"
      gap={spacing.xxs}
      {...wrapperProps}
    >
      {appStoreLink && (
        <ImageLink
          url={appStoreLink}
          name="App Store"
          height="3.5rem"
          width="11.875rem"
          src={getStaticAssetPath(`/images/storeBadges/ios/${locale}/app-store-badge.svg`)}
          alt={t('downloadAppStore')}
          testId="app-store-link"
        />
      )}
      {googlePlayLink && (
        <ImageLink
          url={googlePlayLink}
          name="Google Play"
          height="3.5rem"
          width="11.875rem"
          src={getStaticAssetPath(`/images/storeBadges/android/${locale}/google-play-badge.svg`)}
          alt={t('downloadGooglePlay')}
          testId="google-play-link"
        />
      )}
    </Box>
  )
}
